'use client' // Error components must be Client Components

import { useEffect } from 'react'
import ErrorFallback from '@components/error-fallback'

export default function Error({
  error,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error)
  }, [error])

  return <ErrorFallback error={error} />
}
